import React, { useState, useEffect } from "react";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Box from './Box'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import plan from './imgs/plan.png'

function App() {
  const [data, setData] = useState([]);

  const getData = async () => {
    let headers = new Headers();
    let url = "http://185.98.137.192:5000/file";
    let response = await fetch(url, { method: "GET", headers: headers });
    let jsonData = await response.json();
    setData(jsonData)
    console.log(jsonData)
  };

  useEffect(() => {
    getData([]);
  }, []);

  return (
    <>
      <Row>
        <Col md={1} xs={2} style={{ height: "50px" }} className={'bg-Vert'}>
        </Col>
        <Col md={2} xs={4}>
          RAS
        </Col>
        <Col md={1} xs={2} style={{ height: "50px" }} className={'bg-Bleu'}>
        </Col>
        <Col md={2} xs={4}>
          Presque RAS, discuter avec l'équipe avant la balade
        </Col>
        <Col md={1} xs={2} style={{ height: "50px" }} className={'bg-Rouge'}>
        </Col>
        <Col md={2} xs={4}>
          Balade difficile (Sur autorisation de l'équipe)
        </Col>
        <Col md={1} xs={2} style={{ height: "50px" }} className={'bg-Noir'}>
        </Col>
        <Col md={2} xs={4}>
          Balade dangereuse (Sur autorisation de l'équipe)
        </Col>

        <h3>Plan du chenil</h3>

        <Col md={4}>
          <Row>
            <Col md={4}>
              <Box num="67" data={data} borderColor="#fff2cc" />
              <Box num="66" data={data} borderColor="#fff2cc" />
              <Box num="65" data={data} borderColor="#fff2cc" />
              <Box num="64" data={data} borderColor="#fff2cc" />
              <Box num="63" data={data} borderColor="#fff2cc" />
              <Box num="62" data={data} borderColor="#fff2cc" />
              <Box num="61" data={data} borderColor="#fff2cc" />
              <Box num="60" data={data} borderColor="#fff2cc" />
              <Box num="59" data={data} borderColor="#fff2cc" />
              <Box num="58" data={data} borderColor="#fff2cc" />
              <Box num="57" data={data} borderColor="#fff2cc" />
              <Box num="56" data={data} borderColor="#fff2cc" />
              <Box num="55" data={data} borderColor="#fff2cc" />
            </Col>
            <Col md={4}>
              <Box num="68" data={data} borderColor="#dae8fc" />
              <Box num="69" data={data} borderColor="#dae8fc" />
              <Box num="70" data={data} borderColor="#dae8fc" />
              <Box num="71" data={data} borderColor="#dae8fc" />
              <hr />
              <Box num="54" data={data} borderColor="#f8cecc" />
              <Box num="53" data={data} borderColor="#f8cecc" />
            </Col>
            <Col md={4}>
              <Box num="1" data={data} borderColor="#d5e8d4" />
              <Box num="2" data={data} borderColor="#d5e8d4" />
              <Box num="3" data={data} borderColor="#d5e8d4" />
              <Box num="4" data={data} borderColor="#d5e8d4" />
              <Box num="5" data={data} borderColor="#d5e8d4" />
              <Box num="6" data={data} borderColor="#d5e8d4" />
              <Box num="7" data={data} borderColor="#d5e8d4" />
              <Box num="8" data={data} borderColor="#d5e8d4" />
              <Box num="9" data={data} borderColor="#d5e8d4" />
              <Box num="10" data={data} borderColor="#d5e8d4" />
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <img width="100%" alt="plan" src={plan} />
        </Col>
        <Col md={4}>
          <Row>
            <Col md={4}>
              <Box num="11" data={data} borderColor="#ffe6cc" />
              <Box num="12" data={data} borderColor="#ffe6cc" />
              <Box num="13" data={data} borderColor="#ffe6cc" />
              <Box num="14" data={data} borderColor="#ffe6cc" />
              <Box num="15" data={data} borderColor="#ffe6cc" />
              <Box num="16" data={data} borderColor="#ffe6cc" />
              <hr />
              <Box num="44" data={data} borderColor="#e1d5e7" />
              <Box num="45" data={data} borderColor="#e1d5e7" />
              <Box num="46" data={data} borderColor="#e1d5e7" />
              <Box num="47" data={data} borderColor="#e1d5e7" />
              <Box num="48" data={data} borderColor="#e1d5e7" />
              <Box num="49" data={data} borderColor="#e1d5e7" />
              <Box num="50" data={data} borderColor="#e1d5e7" />
              <Box num="51" data={data} borderColor="#e1d5e7" />
              <Box num="52" data={data} borderColor="#e1d5e7" />
            </Col>
            <Col md={4}>
              <Box num="17" data={data} borderColor="black" />
              <Box num="18" data={data} borderColor="black" />
              <Box num="19" data={data} borderColor="black" />
              <Box num="20" data={data} borderColor="black" />
              <Box num="21" data={data} borderColor="black" />
              <Box num="22" data={data} borderColor="black" />
              <Box num="23" data={data} borderColor="black" />
              <Box num="24" data={data} borderColor="black" />
              <Box num="25" data={data} borderColor="black" />
              <Box num="26" data={data} borderColor="black" />
              <Box num="27" data={data} borderColor="black" />
              <Box num="28" data={data} borderColor="black" />
            </Col>
            <Col md={4}>
              <Box num="29" data={data} borderColor="#f5f5f5" />
              <Box num="30" data={data} borderColor="#f5f5f5" />
              <Box num="31" data={data} borderColor="#f5f5f5" />
              <Box num="32" data={data} borderColor="#f5f5f5" />
              <Box num="33" data={data} borderColor="#f5f5f5" />
              <Box num="34" data={data} borderColor="#f5f5f5" />
              <Box num="35" data={data} borderColor="#f5f5f5" />
              <Box num="36" data={data} borderColor="#f5f5f5" />
              <Box num="37" data={data} borderColor="#f5f5f5" />
              <Box num="38" data={data} borderColor="#f5f5f5" />
              <Box num="39" data={data} borderColor="#f5f5f5" />
              <Box num="40" data={data} borderColor="#f5f5f5" />
              <Box num="41" data={data} borderColor="#f5f5f5" />
              <Box num="42" data={data} borderColor="#f5f5f5" />
              <Box num="43" data={data} borderColor="#f5f5f5" />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default App;

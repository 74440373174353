
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Badge from 'react-bootstrap/Badge';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';



function Box(props) {

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>Box #{props.num} (Allée <span style={{ color: props.borderColor, textShadow: "1px 1px 1px black" }}>■</span>)</Card.Title>
          <Card.Text>
            <Row>
              {
                props.data.map(dog => {
                  if (dog.Box == props.num)
                    return (
                      <Col className='box' md={12} xs={4}>
                        <p>
                          <span className={dog.Couleur}>⬤</span> {dog.Nom}
                          {dog.Commentaire != null && <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip" {...props}>
                              {dog.Commentaire}
                            </Tooltip>}
                          >
                            <Badge pill bg="danger" style={{ float: 'right' }}>Note</Badge>
                          </OverlayTrigger>
                          }
                        </p>
                      </Col>
                    )
                })
              }
            </Row>
          </Card.Text>
        </Card.Body>
      </Card>

    </>
  );
}

export default Box;
